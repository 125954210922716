import { css } from "@emotion/react";
import { Col, Flex, Space, Typography } from "antd";
import { LogoWhite, LeftSideImage } from "assets/Icons";
import { useTranslation } from "react-i18next";
import { LeftSideProps } from "../types";

const { Text, Title } = Typography;

const titleCSS = css`
  font-size: 1.8rem !important;
  color: white !important;
`;

const textCSS = css`
  color: white;
`;

export const LeftSide = ({}: LeftSideProps) => {
  const { t } = useTranslation();

  return (
    <Col
      span={6}
      xxl={4}
      css={(theme: any) => css`
        background-color: ${theme.colorPrimary};
        display: flex;
        flex-direction: column;
        align-items: left;
 
        padding: 20px 20px;
        height: 100%;
        overflow: hidden;
        position: relative;
      `}
    >
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <LogoWhite width={150} />
      </div>

      <Space direction="vertical" align="center" size={60}>
        <Flex vertical>
          <Title css={titleCSS}>{t("auth.leftBannerTitle")}</Title>

          <Text css={textCSS}>{t("auth.leftBannerDescription")}</Text>
        </Flex>
        <div css={
          css`
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            svg {
              width: 100%;
              height: 450px;
            }
          `
        }>
          <LeftSideImage />
        </div>
      </Space>
    </Col>
  );
};
