import { css } from "@emotion/react";
import { theme } from "antd";

 
export const userDropdown = css`
  cursor: pointer;
  .ant-space-item {
    height: auto;
  }
`;

export const divider = css`
  padding: 0;
  height: "50%";
  width: "1px";
  margin: 0;
  margintop: "20px";
  marginright: "10px";
  background: "#f0f0f0";
`;

export const divider2 = css`
  padding: 0;
  height: "50%";
  width: "1px";
  margin: 15;
  margintop: "20px";
  background: "#f0f0f0";
`;
 
export const headerText = css`
  height: "100%";
  color: "#f0f0f0";
  fontweight: 400;
  opacity: 0.7;
`;
