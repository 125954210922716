import { EmployeeBirthDatesPayload } from "pages/Employees/api/Birthdates/useGetBirthDates";
import { GetEmploymentTypesPayload } from "./EmploymentTypes/useGetEmployeementTypes";
import { EmployeeLeaveDaysPayload } from "./LeaveDays/useGetEmployeeLeaveDays";
import { GetEmployeesPayload } from "./useGetEmployees";
import { LeaveDayRequestsPayload } from "pages/LeaveRequests";
import { PaymentRequestPayload } from "pages/Payments";

export const employeeKeys = {
  all: ["employeesQuerys"] as const,
  getEmployee: (id: string) => [...employeeKeys.all, id] as const,
  getEmployees: (payload: GetEmployeesPayload) =>
    [...employeeKeys.all, payload] as const,
  getBirthdayEmployees: (payload: EmployeeBirthDatesPayload) =>
    [...employeeKeys.all, payload] as const,
};
export const employeementTypeKeys = {
  all: ["employeementTypes"] as const,
  getEmployeementTypes: (payload: GetEmploymentTypesPayload) =>
    [...employeementTypeKeys.all, payload] as const,
};

export const LeaveDaysKeys = {
  all: ["leaveDays"] as const,
  getEmployeeLeaveDays: (payload: EmployeeLeaveDaysPayload) =>
    [...LeaveDaysKeys.all, payload] as const,
  getEmployeeLeaveDayRequests: (payload?: LeaveDayRequestsPayload) =>
    [...LeaveDaysKeys.all, payload] as const,
};

export const PaymentsKeys = {
  all: ["employee-payments"] as const,
  getEmployeePaymentRequests: (payload: PaymentRequestPayload) =>
    [...PaymentsKeys.all, payload] as const,
};
