import { css } from "@emotion/react";

type SearchableSelectProps = {
  theme?: any;
  width?: string;
  height?: string;
  custom?: string;
};

export const SearchableSelectCSS = ({
  theme,
  width,
  height,
  custom,
}: SearchableSelectProps) =>
  css`
    width: ${width || "100%"};
    height: ${height || "40px"};
    ${custom}
  ` || undefined;
