import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, MutationConfig } from "lib/react-query";

export type AddLeaveDayRequestPayload = {
  start_date: string;
  end_date: string;
  leavedays_id: number;
  replacement_user_id?: number;
  return_date?: string;
  description: string;
};

export type addLeaveDayRequest = ExtractFnReturnType<typeof addLeaveDayRequest>;

export const addLeaveDayRequest = async (
  payload: AddLeaveDayRequestPayload
) => {
  return axiosMain.post("/leavedays-requests", payload);
};

export const useAddLeaveDaysRequest = (
  config?: MutationConfig<typeof addLeaveDayRequest>
) => {
  return useExtendedMutation({
    ...config,
    mutationFn: addLeaveDayRequest,
  });
};
