import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { MutationConfig } from "lib/react-query";

export type SendEmailVerificationPayload = {
  email: string;
};

export const sendEmailVerification = async (
  payload: SendEmailVerificationPayload
) => {
  return axiosMain.post("/auth/send-email-verification", payload);
};

type QueryFnType = typeof sendEmailVerification;

export const useSendEmailVerification = (
  config?: MutationConfig<QueryFnType>
) => {
  return useExtendedMutation({
    ...config,
    mutationFn: sendEmailVerification,
  });
};
