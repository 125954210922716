export type Indexable<T> = T & {
  [key: string]: any;
};

export type ActiveStatus = "active" | "inactive";
export type EmployeeLeaveDaysStatus = "pending" | "approved" | "rejected";

export type PaymentMethod = "Gross" | "Net";
export type PaymentTimeRange = "Annual" | "Monthly" | "Weekly" | "Daily";

export type Roles = "employee" | "manager" | "admin";

export enum PaymentRequestStatus {
  PAID = "paid",
  PARTIAL = "partial",
  UNPAID = "unpaid",
}
export enum Priority {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export type LeaveType =
  | "annual"
  | "sick"
  | "maternity"
  | "paternity"
  | "compassionate"
  | "study"
  | "unpaid"
  | "other";

export type Company = {
  id: number;
  name: string;
  email: string;
  country?: string;
  city: string;
  address?: string;
  phone?: string;
  status: "active" | "inactive";
  timezone?: string;
  parent_company_id?: number;
};

export type EmploymentType = {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: string;
};

export type Employee = {
  id: number;
  name: string;
  surname: string;
  identityNumber: null | string;
  email: string;
  phone: null | string;
  employmentType: EmploymentType;
  startDate: string | Date;
  endDate: null | string;
  note: null | string;
  company: Company;
  birthdate: string | Date;
  department: Department;
};

export type Department = {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  jobs: any[]; // You might want to replace 'any[]' with the actual type for jobs if you have that information
};

export type leavedays = {
  id: number;
  name: string;
  slug: string;
  description: string;
  default_days: number;
  created_at: string;
  updated_at: string;
};

export type Payment = {
  id: number;
  name: string;
  slug: string;
  description: string;
  created_at: string;
  updated_at: string;
};

export type LeaveDayResponse = {
  id: number;
  start_date: string;
  end_date: string;
  leavedays: leavedays;
  replacement_user_id: number;
  return_date: string;
  employee: Employee;
  days_taken: number;
  description: null | string;
  file: null | string;
  desicion_date: null | string;
  status: EmployeeLeaveDaysStatus;
  created_at: Date;
  updated_at: string;
};

export type PaymentRequest = {
  id: number;
  employee: Employee;
  decision_by: Employee;
  amount: number;
  intallments: number;
  status: PaymentRequestStatus;
  description: string;
  paymentMethod: PaymentMethod;
  paymentTimeRange: PaymentTimeRange;
  decision_comment: string;
  paymentType: Payment;
  priority: Priority;
  request_date: Date;
  decision_date: Date;
  created_at: Date;
  updated_at: Date;
};


export interface Holiday {}