import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

export const PasswordTab = () => {
  const { t } = useTranslation();
  const p = {
    password: "",
    passwordConfirm: "",
  };

  return (
    <>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: t("auth.passwordRequired"),
          },
        ]}
      >
        <Input type="password" placeholder={t("auth.passwordPlchldr")} />
      </Form.Item>
      <Form.Item
        name="passwordConfirm"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("auth.passwordRepaetRequired"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t("auth.passwordRepeatNotMatch"))
              );
            },
          }),
        ]}
      >
        <Input type="password" placeholder={t("auth.passwordRepatedPlchldr")} />
      </Form.Item>
    </>
  );
};
