import { ThemeConfig, ConfigProvider, theme } from "antd";
import { useCommonContext } from "providers/CommonProvider";

const tabs = (isDarkMode: boolean) => {
  return isDarkMode ? "#9a5fe8" : "#6b26da";
};

const AntProvider = ({ children }: any) => {
  const { darkAlgorithm, defaultAlgorithm } = theme;
  const { isDarkMode } = useCommonContext();

  const themeConfig: ThemeConfig = {
    algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    token: {
      colorTextBase: isDarkMode ? "#ffffff" : "#000000",
      colorBgBase: isDarkMode ? "#191919" : "#ffffff",
      colorBgLayout: isDarkMode ? "#121212" : "#f4f3f6",
      colorPrimary: isDarkMode ? "#6b26da" : "#6b26da",
    },
    inherit: true,
    components: {
      Button: {
        colorBgBase: "#6b26da",
        linkHoverBg: "#f8f0ff",
        colorLink: "#6b26da",
        colorLinkHover: "#6b26da",
        colorLinkActive: "#6b26da",

        primaryShadow: "0 2px 0 rgba(138, 5, 255, 0.06)",
      },
      Layout: {
        siderBg: isDarkMode ? "#474c50" : "#ffffff",
        lightSiderBg: isDarkMode ? "#474c50" : "#ffffff",
        headerBg: isDarkMode ? "#2d2d2d" : "#ffffff",
        headerPadding: 0,
      },
      Table: {
        headerColor: isDarkMode ? "#ffffff" : "#000000",
      },
      Tabs: {
        itemColor: isDarkMode ? "#ffffff" : "#000000A6",
        itemSelectedColor: tabs(isDarkMode),
        itemActiveColor: tabs(isDarkMode),
        itemHoverColor: tabs(isDarkMode),
        colorBorder: tabs(isDarkMode),
      },
      Menu: {
        itemColor: "#A0A8B1",
        iconSize: 20,
        fontSize: 14,
        collapsedIconSize: 20,
        itemHoverColor: "#6b26da",
        itemHoverBg: "#f8f0ff",
        itemSelectedColor: isDarkMode ? "#ffffff" : "#6b26da",
      },
      Input: {
        paddingBlock: 10,
      },
    },
  };
  return <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>;
};

export default AntProvider;
