import { axiosMain } from "lib/axios";
import { AuthUser } from "../types";

export const getUser = async (): Promise<AuthUser> => {
  const response = await axiosMain.get("/auth/profile").catch((err) => {
    throw err;
  });

  return response.data;
};
