export const findOption = (options: any[], value: any) => {
    if (value) {
      const foundOption = options?.find((option) => {
        return option.label
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      });
      return foundOption ? [foundOption] : [];
    }
  
    return options;
  };