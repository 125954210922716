import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { createContext, useContext } from "react";

type NotificationContextType = {
  api: NotificationInstance;
  contextHolder: any;
};

// create context which takes jsx element
export const NotificationContext: any = createContext<NotificationContextType>({
  api: {} as NotificationInstance,
  contextHolder: {},
});

const NotificationProvider = ({ children }: any) => {
  const [api, contextHolder] = notification.useNotification();
  notification.config({
    duration: 3,
    placement: "topRight",
  });

  return (
    <NotificationContext.Provider
      value={{
        api,
        contextHolder,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useSettings must be used within an SettingsProvider");
  }

  return context as NotificationContextType;
};

export { NotificationProvider, useNotification };
