import { css } from "@emotion/react";
import { Flex, Spin, Typography } from "antd";
import { RenderIf } from "components/Common";
const { Text } = Typography;

const NotFound = ({ isLoading, data }: { isLoading: boolean; data: unknown }) => {
  return (
    <Flex
      justify="center"
      align="center"
      css={css`
        height: 100%;
        padding: 10px;
      `}
    >
      <RenderIf condition={isLoading}>
        <Spin size="small" />
      </RenderIf>

      <RenderIf condition={!isLoading && !data}>
        <Text
          css={css`
            margin: 0;
            padding: 0;
          `}
          type="secondary"
        >
          No results found
        </Text>
      </RenderIf>
    </Flex>
  );
};

export default NotFound;
