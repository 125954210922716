import { PaymentRequestPayload, PaymentRequestsPayload } from ".";





export const paymentRequestsKeys = {
  all: ["paymentRequests"] as const,
  getPaymentRequest: (payload?: PaymentRequestPayload) =>
    [...paymentRequestsKeys.all, payload] as const,
  getPaymentRequests: (payload?: PaymentRequestsPayload) =>
    [...paymentRequestsKeys.all, payload] as const,
};