import { useNotification } from "contexts";
import { useUser } from "lib/auth";
import { queryClient } from "lib/react-query";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "utils/storage";

const AuthProvider = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { api } = useNotification();
  useUser({
    onError: () => {
      storage.clearToken();
      api.error({
        message: "Your Session has expired. Please login again.",
      });
      navigate("/auth/login");
    },

    enabled: !!storage.getToken(),
  });
  useEffect(() => {
    if (!storage.getToken()) {
      storage.clearToken();
    }
  }, [navigate]);

  useEffect(() => {
    queryClient.refetchQueries(["authenticated-user"]);
  }, [location.pathname]);

  return <>{children}</>;
};

export default AuthProvider;
