// write me a hook with the following functionality register form
import {
  CompanyTab,
  ContactTab,
  PasswordTab,
  PersonalTab,
} from "pages/Auth/components/Register";
import { useEffect, useState } from "react";
import { RegisterCredentialsDTO } from "../types";
import { Form } from "antd";
import { useRegister } from "lib/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNotification } from "contexts";

export type RegisterTab = {
  slug: string;
  title: string;
  component: any;
  disabled: boolean;
};

const useRegisterForm = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { mutateAsync, isLoading } = useRegister();
  const { api } = useNotification();

  const { t } = useTranslation();

  const [values, setValues] = useState<RegisterCredentialsDTO>({
    name: "",
    surname: "",
    city: null,
    companyName: "",
    companyEmail: "",
    registerWorkerCount: "0",
    email: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  });
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const formValues = Form.useWatch([], form);

  const [tabs, setTabs] = useState<RegisterTab[]>([
    {
      slug: "personal",
      title: t("auth.personalInfo"),
      component: PersonalTab,
      disabled: false,
    },
    {
      slug: "company",
      title: t("auth.company"),
      component: CompanyTab,
      disabled: true,
    },
    {
      slug: "contact",
      title: t("auth.contact"),
      component: ContactTab,
      disabled: true,
    },
    {
      slug: "password",
      title: t("auth.password"),
      component: PasswordTab,
      disabled: true,
    },
  ]);
  const openNotificationWithIcon = () => {
    api["success"]({
      message: "Registration Successful",
      description:
        "You can activate your account by clicking on the activation link sent to your e-mail address.",
    });
  };

  const updateTab = (index: number, status: boolean) => {
    const tab = tabs[index];
    tab.disabled = status;
    setTabs([...tabs.slice(0, index), tab, ...tabs.slice(index + 1)]);
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        updateTab(activeTabIndex + 1, false);
      },
      () => {
        updateTab(activeTabIndex + 1, true);
      }
    );
  }, [formValues]);

  const handleTab = (index: number) => {
    setActiveTab(tabs[index].slug);
    setActiveTabIndex(index);
  };

  const handleForm = async () => {
    if (activeTabIndex === tabs.length - 1) {
      await mutateAsync(values);
      openNotificationWithIcon();
      navigate("/auth/login");
      return;
    }

    setActiveTab(tabs[activeTabIndex + 1].slug);
    setActiveTabIndex(activeTabIndex + 1);
  };

  const handleFormChange = (formValues: RegisterCredentialsDTO) => {
    setValues({ ...values, ...formValues });
  };
  return {
    form,
    values,
    handleForm,
    activeTab,
    handleTab,
    tabs,
    activeTabIndex,
    handleFormChange,
    isLoading
  };
};

export default useRegisterForm;
