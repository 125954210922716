import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "lib/react-query";

const ReactQueryProvider = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      {import.meta.env.DEV && <ReactQueryDevtools />}

      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
