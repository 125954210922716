import {
  ClockCircleOutlined,
  DollarOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Flex, FloatButton, Form, Tag, Typography } from "antd";
import { FormModal } from "components/FormModal";
import { useAddLeaveDaysRequest, useGetEmployees } from "pages/Employees";
import { useGetEmployeeLeaveDays } from "pages/Employees/api/LeaveDays/useGetEmployeeLeaveDays";
import { AdvangePaymentModal } from "pages/Payments/components/Modals/AdvancePayment";
import { useAdvangePaymentModalActions } from "pages/Payments/store/AdvancePaymentModal";
import { useState } from "react";

const { Text } = Typography;

const LeaveDaysRequestModal = ({ setOpen, open }: any) => {
  const { mutateAsync: addLeaveDayRequest } = useAddLeaveDaysRequest({
    successMessage: "Leave request has been sent successfully",
    errorMessage: "Something went wrong, please try again later",
    refetchQueries: ["leavedayRequests"],
  });
  const [form] = Form.useForm();

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = async (values: any) => {
    addLeaveDayRequest(values);
  };

  const formFields: FormModal["formFields"] = [
    {
      type: "date",
      label: "Start Date",
      rules: [{ required: true, message: "Please select start date" }],
      name: "start_date",
      placeholder: "Select start date",
    },
    {
      type: "date",
      label: "End Date",
      rules: [{ required: true, message: "Please select end date" }],
      name: "end_date",
      placeholder: "Select end date",
    },

    {
      type: "searchableSelect",
      label: "Leave Days",
      name: "leavedays_id",
      queryFn: useGetEmployeeLeaveDays,
      optionFormat: (option) => ({
        value: option.leavedays.id,
        label: (
          <Flex align="center" justify="space-between">
            {option.leavedays.name}
            <Tag>
              <Text style={{ fontSize: 12 }} type="secondary">
                {option.remaining_days} days remaining
              </Text>
            </Tag>
          </Flex>
        ),
      }),
      placeholder: "Select leave days",
    },

    {
      type: "searchableSelect",
      label: "Replacement User",
      name: "replacement_user_id",
      queryFn: useGetEmployees,
      optionFormat: (option) => ({
        value: option.id,
        label: (
          <Flex align="center" gap={5}>
            <Text>{option.name}</Text>
            <Text>{option.surname}</Text>
          </Flex>
        ),
      }),
      placeholder: "Select replacement user",
    },
    {
      type: "date",
      label: "Return Date",
      name: "return_date",
      placeholder: "Select return date",
    },
    {
      type: "file",
      label: "File",
      name: "file",
      placeholder: "Upload file",
    },

    {
      type: "textarea",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 4,
    },
  ];

  return (
    <>
      <FormModal
        formFields={formFields}
        formInstance={form}
        onOk={handleFormSubmit}
        onCancel={handleModalClose}
        open={open}
        setOpen={setOpen}
        title="Request a leave"
      />
    </>
  );
};

export const Widget = () => {
  const [openLeaveDaysModal, setOpenLeaveDaysModal] = useState(false);
  const { open: openPaymentModal } = useAdvangePaymentModalActions();

  const handleModalOpen = () => {
    setOpenLeaveDaysModal(true);
  };

  const handlePaymentModalOpen = () => {
    openPaymentModal();
  };

  return (
    <>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 24 }}
        icon={<PlusOutlined />}
      >
        <FloatButton
          icon={<ClockCircleOutlined />}
          onClick={handleModalOpen}
          tooltip="Request a leave"
        />

        <FloatButton
          icon={<DollarOutlined />}
          onClick={handlePaymentModalOpen}
          tooltip="Request advance payment"
        />
      </FloatButton.Group>
      <LeaveDaysRequestModal
        open={openLeaveDaysModal}
        setOpen={setOpenLeaveDaysModal}
      />

      <AdvangePaymentModal />
    </>
  );
};
