import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, QueryConfig } from "lib/react-query";
import { useQuery } from "@tanstack/react-query";
import { LeaveDayResponse } from "types";
import { leavedayRequestsKeys } from "./keys";
import dayjs from "dayjs";

export type LeaveDayRequestPayload = {
  id: number;
};

export type LeaveDayRequestResponse = LeaveDayResponse;

const getLeaveDayRequest = async ({ id }: LeaveDayRequestPayload) => {
  const response: LeaveDayRequestResponse = (
    await axiosMain.get("/Leavedays-requests/" + id)
  ).data;

  const formattedResponse = {
    ...response,
    start_date: dayjs(response.start_date),
    end_date: dayjs(response.end_date),
    created_at: dayjs(response.created_at),
    updated_at: dayjs(response.updated_at),
    desicion_date: dayjs(response.desicion_date),
    return_date: dayjs(response.return_date),
  };
  return formattedResponse;
};

type QueryFnType = typeof getLeaveDayRequest;

export const useGetLeaveDaysRequest = (
  payload: LeaveDayRequestPayload,
  config?: QueryConfig<QueryFnType>
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: leavedayRequestsKeys.getLeaveDayRequest(payload),

    queryFn: ({ queryKey }) => {
      const [_, payload] = queryKey as ReturnType<
        typeof leavedayRequestsKeys.getLeaveDayRequest
      >;
      return getLeaveDayRequest(payload);
    },
  });
};
