import { HomeOutlined, ClockCircleOutlined, CreditCardOutlined, RiseOutlined, CalendarOutlined, MailOutlined, SettingOutlined } from "@ant-design/icons";

export const employeeMenuItems = [
    {
      icon: <HomeOutlined />,
      label: "Dashboard",
      key: "dashboard",
      path: "/",
    },
    {
      icon: <ClockCircleOutlined />,
      label: "Leave Requests",
      key: "leaves",
      path: "/leaves",
    },
    {
      icon: <CreditCardOutlined />,
      label: "Expences",
      key: "expences",
      path: "/expences",
    },
    {
      icon: <RiseOutlined />,
      label: "Reports",
      key: "reports",
      active: false,
      path: "/reports",
    },

    {
      icon: <CalendarOutlined />,
      label: "Calendar",
      key: "calendar",
      path: "/calendar",
    },
    {
      icon: <MailOutlined />,
      label: "Mail",
      key: "mail",
      path: "/mail",
    },
    {
      icon: <SettingOutlined />,
      label: "Settings",
      key: "settings",
      path: "/settings",
    },
  ];