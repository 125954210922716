import { InfoCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Typography, Flex, Tooltip } from "antd";
import { useCommonContext } from "providers/CommonProvider";
import { RenderIf } from "../RenderIf";

type InfoRowProps = {
  text: string | React.ReactNode;
  content: string | React.ReactNode;
  tooltip?: {
    content: string;
  };
};

const { Text } = Typography;

export const InfoRow = ({ text, content, tooltip }: InfoRowProps) => {
  const { isDarkMode } = useCommonContext();
  return (
    <Flex
      justify="space-between"
      align="center"
      css={(theme: any) => css`
        padding: 18px;
        border-radius: 8px;
        background-color: ${isDarkMode ? "#000000" : "#fafafa"};
      `}
    >
      <Flex align="center" gap={4}>
        <Text
          css={(theme: any) => css`
            color: ${theme.colorTextBase};
          `}
        >
          {text}
        </Text>
        <RenderIf condition={!!tooltip}>
          <Tooltip title={tooltip?.content}>
            <InfoCircleOutlined />
          </Tooltip>
        </RenderIf>
      </Flex>
      <Text>{content}</Text>
    </Flex>
  );
};
