import { createContext, useEffect, useState } from "react";
import { createContextualCan } from "@casl/react";
import { AnyAbility } from "@casl/ability";
import ability from "config/Permissions";
import { Roles } from "types";
import { useUser } from "lib/auth";
import storage from "utils/storage";
import { RenderIf } from "components/Common";

export const AbilityContext = createContext<AnyAbility>({} as AnyAbility);
export const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = ({ children }: any) => {
  const [abilities, setAbilities] = useState<any>();
  const token = storage.getToken();

  const { data: user, isLoading } = useUser({
    enabled: !!token,
  });

  useEffect(() => {
    if (!isLoading && !!user) {
      setAbilities(ability(user?.role as Roles));
    }
  }, [isLoading]);

  return (
    <AbilityContext.Provider value={abilities}>
      <RenderIf condition={!!abilities || !token}>{children}</RenderIf>
    </AbilityContext.Provider>
  );
};

export default AbilityProvider;
