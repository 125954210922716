const storagePrefix = "hrbuddy-";

const storage = {
  getToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}token`);

    if (token && token !== "undefined") {
      try {
        return JSON.parse(token);
      } catch (error) {
        window.localStorage.removeItem(`${storagePrefix}token`);
        throw new Error(`Error parsing token from localStorage`);
      }
    }
    return null;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },

  setItem: (key: string, value: string) => {
    window.localStorage.setItem(
      `${storagePrefix}${key}`,
      JSON.stringify(value)
    );
  },
  getItem: (key: string) => {
    const item = window.localStorage.getItem(`${storagePrefix}${key}`);

    if (item && item !== "undefined") {
      try {
        return JSON.parse(item);
      } catch (e) {
        throw new Error(`Error parsing item ${key} from localStorage`);
      }
    }

    return null;
  },
  clearItem: (key: string) => {
    window.localStorage.removeItem(`${storagePrefix}${key}`);
  },
};

export default storage;
