import { useMutation } from "@tanstack/react-query";
import { axiosMain } from "lib/axios";
import { MutationConfig } from "lib/react-query";

export type SendForgetPasswordEmailPayload = {
  email: string;
};

export const sendForgetPasswordEmail = async (
  payload: SendForgetPasswordEmailPayload
) => {
  return axiosMain.post("/auth/send-password-reset", payload);
};

type QueryFnType = typeof sendForgetPasswordEmail;

export const useSendForgetPasswordEmail = (
  config?: MutationConfig<QueryFnType>
) => {
  return useMutation({
    ...config,
    mutationFn: sendForgetPasswordEmail,
  });
};
