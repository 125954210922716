import { css } from "@emotion/react";
import { Tag } from "antd";
import { Indexable, Priority } from "types";

type PriortyTagProps = {
  status?: Priority;
  text?: string;
};

export const PriortyStatusTag = ({ status }: PriortyTagProps) => {
  const colors: Indexable<object> = {
    medium: "orange",
    low: "green",
    high: "red",
  };

  return (
    <Tag
      css={css`
        text-transform: capitalize;
      `}
      color={colors[status!]}
    >
      {status}
    </Tag>
  );
};
