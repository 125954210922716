import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, MutationConfig } from "lib/react-query";
import { Priority } from "types";

export type AddPaymentRequestPayload = {
  amount: number;
  intallments: number;
  request_date: Date;
  priority: Priority;
  description: string;
};

export type addPaymentRequest = ExtractFnReturnType<typeof addPaymentRequest>;

export const addPaymentRequest = async (payload: AddPaymentRequestPayload) => {
  return axiosMain.post("/payment-requests", payload);
};

export const useAddPaymentsRequest = (
  config?: MutationConfig<typeof addPaymentRequest>
) => {
  return useExtendedMutation({
    ...config,
    mutationFn: addPaymentRequest,
  });
};
