import { proxy, useSnapshot } from "valtio";

type AddPaymentDrawerState = {
  isOpen: boolean;
  paymentId?: number;
};

const initialState = proxy<AddPaymentDrawerState>({
  isOpen: false,
  paymentId: undefined,
});

const actions = {
  open: () => {
    initialState.isOpen = true;
  },
  close: () => {
    initialState.isOpen = false;
  },
  dispatch: (paymentId?: number) => {
    initialState.paymentId = paymentId;
    initialState.isOpen = true;
  },
};

export const useAddPaymentDrawerState = () => {
  return useSnapshot(initialState);
};

export const useAddPaymentDrawerActions = () => {
  return actions;
};
