import { createContext, useContext, useState } from "react";
import storage from "utils/storage";

type CommonContextType = {
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
};

export const CommonContext: any = createContext<CommonContextType>({
  isDarkMode: false,
  setIsDarkMode: () => {},
});

const CommonProvider = ({ children }: any) => {
  const [isDarkMode, setIsDarkMode] = useState(
    storage.getItem("isDarkMode") === "true"
  );

  const handleSetIsDarkMode = (isDarkMode: boolean) => {
    setIsDarkMode(isDarkMode);
    storage.setItem("isDarkMode", isDarkMode.toString());
  };

  return (
    <CommonContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode: handleSetIsDarkMode,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

const useCommonContext = (): CommonContextType => {
  const context = useContext(CommonContext);

  if (!context) {
    throw new Error("useCommon must be used within an CommonProvider");
  }

  return context as CommonContextType;
};

export { CommonProvider, useCommonContext };
