import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { MutationConfig } from "lib/react-query";

export type EmailVerificationPayload = {
  code?: string;
  verifyToken?: string;
  email?: string;
};

export const emailVerification = async (payload: EmailVerificationPayload) => {
  return axiosMain.post("/auth/verify-email", payload);
};

type QueryFnType = typeof emailVerification;

export const useEmailVerification = (config?: MutationConfig<QueryFnType>) => {
  return useExtendedMutation({
    ...config,
    mutationFn: emailVerification,
  });
};
