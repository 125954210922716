import { UploadOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import {
  FormInstance,
  Modal,
  Form,
  Select,
  Input,
  DatePicker,
  Upload,
  Button,
  InputNumber,
  InputNumberProps,
} from "antd";
import { Rule } from "antd/es/form";
import { BaseOptionType } from "antd/es/select";
import { SearchableSelect } from "components/SearchableSelect";
import dayjs from "dayjs";

type FormField = {
  type:
    | "select"
    | "input"
    | "date"
    | "searchableSelect"
    | "file"
    | "textarea"
    | "number";
  label: string;
  rules?: Rule[];
  name: string;
  options?: BaseOptionType[];
  placeholder?: string;
  queryFn?: any;
  optionFormat?: (option: any) => {
    value: any;
    label: string | React.ReactNode;
  };
  rows?: number;
  cols?: number;
} & InputNumberProps;

export type FormModal = {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  onOk: (values: any) => void;
  formInstance: FormInstance;
  initialValues?: any;
  formFields: FormField[];
};

const FormField = ({
  item,
  ...props
}: {
  item: FormModal["formFields"][0];
}) => {
  switch (item.type) {
    case "select":
      return (
        <Select
          placeholder={item.placeholder}
          options={item.options}
          allowClear
          {...props}
        />
      );
    case "searchableSelect":
      return (
        <SearchableSelect
          placeholder={item.placeholder}
          queryFn={item.queryFn}
          optionFormat={
            item.optionFormat ??
            ((option) => ({ value: option.id, label: option.name }))
          }
          {...props}
        />
      );
    case "input":
      return <Input {...props} placeholder={item.placeholder} />;
    case "file":
      return (
        <Upload {...props} listType="picture" maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
        </Upload>
      );
    case "textarea":
      return (
        <Input.TextArea
          placeholder={item.placeholder}
          rows={item.rows}
          cols={item.cols}
          {...props}
        />
      );
    case "date":
      return (
        <DatePicker
          css={css`
            width: 100%;
          `}
          allowClear={false}
          {...props}
        />
      );
    case "number":
      return (
        <InputNumber
          css={css`
            width: 100%;
          `}
          placeholder={item.placeholder}
          prefix={item.prefix}
          {...props}
        />
      );
    default:
      return null;
  }
};

export const FormModal = ({
  title,
  open,
  setOpen,
  onCancel,
  onOk,
  formInstance,
  formFields,
  initialValues,
  ...props
}: FormModal) => {
  const handleFormSubmit = (values: any) => {
    onOk(formInstance.getFieldsValue());
    setOpen(false);
  };

  const handleEditModalClose = () => {
    onCancel();
    setOpen(false);
  };

  return (
    <Modal
      title={title}
      open={open}
      okButtonProps={{
        htmlType: "submit",
        form: "modal-form",
        type: "primary",
      }}
      destroyOnClose
      onCancel={handleEditModalClose}
      width={600}
    >
      <Form
        id="modal-form"
        layout="horizontal"
        initialValues={initialValues}
        preserve={false}
        labelWrap={true}
        form={formInstance}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        requiredMark={false}
        onFinish={handleFormSubmit}
        css={css`
          margin-top: 20px;
        `}
      >
        {formFields?.map((item) => (
          <Form.Item
            key={item.name}
            label={item.label}
            name={item.name}
            rules={item.rules}
            getValueProps={(value) => ({
              value: item.type === "date" ? dayjs(value) : value,
            })}
          >
            <FormField item={item} {...props} />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
