import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEmailVerification } from "../api/useEmailVerification";

import { useTranslation } from "react-i18next";

export const EmailConfirmation = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();

  const { mutateAsync: emailVerificationMutate } = useEmailVerification({
    successMessage: t("auth.emailVerifySuccess"),
    errorMessage: t("auth.emailVerifyError"),
  });

  useEffect(() => {
    if (searchParams.get("verifyToken")) {
      const verifyToken = searchParams.get("verifyToken");

      emailVerificationMutate({ verifyToken: verifyToken! }).then((res) => {
        navigation("/auth/login");
      });
    }

    return () => {
      searchParams.delete("verifyToken");
      searchParams.delete("userEmail");
    };
  }, [searchParams]);

  return <></>;
};
