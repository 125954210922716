import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, QueryConfig } from "lib/react-query";
import { useQuery } from "@tanstack/react-query";
import { PaymentRequest } from "types";
import { paymentRequestsKeys } from "./keys";
import dayjs from "dayjs";

export type PaymentRequestPayload = {
  id: number;
};

export type PaymentRequestResponse = PaymentRequest;

const getPaymentRequest = async ({ id }: PaymentRequestPayload) => {
  const response: PaymentRequestResponse = (
    await axiosMain.get("/payment-requests/" + id)
  ).data;

  const formattedResponse = {
    ...response,
    created_at: response.created_at ? dayjs(response.created_at) : null,
    updated_at: response.updated_at ? dayjs(response.updated_at) : null,
    decision_date: response.decision_date
      ? dayjs(response.decision_date)
      : null,
    request_date: response.request_date ? dayjs(response.request_date) : null,
  };
  return formattedResponse;
};

type QueryFnType = typeof getPaymentRequest;

export const useGetPaymentRequest = (
  payload: PaymentRequestPayload,
  config?: QueryConfig<QueryFnType>
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: paymentRequestsKeys.getPaymentRequest(payload),

    queryFn: ({ queryKey }) => {
      const [_, payload] = queryKey as ReturnType<
        typeof paymentRequestsKeys.getPaymentRequest
      >;
      return getPaymentRequest(payload);
    },
  });
};
