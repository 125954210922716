import registerImage from "assets/auth/register.png";
import { RenderIf } from "components/Common";
import { useRegister } from "lib/auth";
import { AuthLayout } from "../components/Layout";
import useRegisterForm, { RegisterTab } from "../hooks/registerForm";
import { Button, Col, Form, Row, Steps, Typography } from "antd";
import { css } from "@emotion/react";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type RegisterTabLayoutProps = {
  tabs: RegisterTab[];
  activeTabIndex: number;
  handleTab: (index: number) => void;
};

const RegisterTabLayout = ({
  tabs,
  activeTabIndex,
  handleTab,
}: RegisterTabLayoutProps) => {
  const isMobile = useWindowDimensions().width < 1300;
  return (
    <Steps
      progressDot
      size="small"
      status="process"
      direction="vertical"
      responsive={false}
      onChange={handleTab}
      current={activeTabIndex}
      css={css`
        width: auto !important;
      `}
      items={tabs.map((tab) => {
        return {
          title: tab.title,
          disabled: tab.disabled,
        };
      })}
    />
  );
};

const { Text } = Typography;

const personalTabCSS = css`
  gap: 1rem;
  padding: 1rem;

  .personal-tab__button {
    width: 100%;
    height: 2.4rem;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 1rem;
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }
  .personal-tab_back_button {
    width: 100%;
    height: 2.4rem;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 1rem;
  }

  .ant-select {
    height: 2.7rem;
  }
`;

export const Register = () => {
  const {
    activeTabIndex,
    tabs,
    handleTab,
    handleForm,
    form,
    handleFormChange,
    values,
    isLoading
  } = useRegisterForm();
  const { mutateAsync } = useRegister();
  const { t } = useTranslation();

  return (
    <AuthLayout
      image={registerImage}
      headerText={t("auth.welcomeTitle")}
      subHeaderText={t("auth.registerSubText")}
      cardHeaderComponent={
        <RegisterTabLayout
          tabs={tabs}
          handleTab={handleTab}
          activeTabIndex={activeTabIndex}
        />
      }
    >
      <Form
        form={form}
        name="personal"
        initialValues={values}
        layout="vertical"
        onFinish={handleForm}
        onValuesChange={handleFormChange}
        css={personalTabCSS}
      >
        {tabs.map((tab, index) => {
          return (
            <RenderIf condition={index === activeTabIndex}>
              <tab.component
                handleTabChange={handleTab}
                submit={mutateAsync}
                handleForm={handleForm}
                key={index}
              />
            </RenderIf>
          );
        })}
        <Form.Item>
          <Row
            justify="center"
            css={css`
              width: 100%;
            `}
            gutter={[0, 0]}
          >
            <Col span={24}>
              <Form.Item>
                <Button
                loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="
            personal-tab__button
          "
                >
                  {tabs.length - 1 === activeTabIndex
                    ? t("auth.register")
                    : t("general.continue")}
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <RenderIf condition={activeTabIndex != 0}>
                <Form.Item>
                  <Button
                    onClick={() => handleTab(activeTabIndex - 1)}
                    type="link"
                    css={(theme: any) => css`
                      color: ${theme.colorTextBase};
                      &:hover {
                        color: ${theme.colorPrimary} !important;
                      }
                      &:focus {
                        color: ${theme.colorPrimary} !important;
                      }
                    `}
                    className="personal-tab_back_button"
                  >
                    {t("general.back")}
                  </Button>
                </Form.Item>
              </RenderIf>
            </Col>
            <Col span={24}>
              <Text
                css={css`
                  width: 100%;
                  margin-top: 1rem;
                  display: inline-block;
                  font-size: 1rem;
                  text-align: center;
                `}
              >
                {t("auth.haveAccount")}{" "}
                <Link to="/auth/login">
                  <Text
                    css={(theme: any) => css`
                      font-weight: 600;
                      color: ${theme.colorPrimary};
                    `}
                  >
                    {t("auth.login")}
                  </Text>
                </Link>
              </Text>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      {/*  <p className="text-xl">
        Hesabınız var mı?{" "}
        <Link to="/auth/login">
          <span className="text-secondary">Giriş Yap</span>
        </Link>
      </p> */}
    </AuthLayout>
  );
};
