import { css } from "@emotion/react";
import { Col, Flex, Space, Typography } from "antd";
import { RenderIf } from "components/Common";
import { RightSideProps } from "../types";
import useWindowDimensions from "hooks/useWindowDimensions";

const { Text, Title } = Typography;

export const RightSide = ({
  children,
  headerText,
  subHeaderText,
  cardHeaderComponent,
}: RightSideProps) => {
  const isMobile = useWindowDimensions().width < 1200;

  return (
    <Col xs={24} xl={18} xxl={20}>
      <Flex
        justify="center"
        align="center"
        gap={28}
        style={{ width: "100%", height: "100%" }}
      >
        <Flex
          vertical
          gap={28}
          align="center"
          css={css`
            .ant-space-item:first-of-type {
              text-align: center !important;
            }
          `}
        >
          <Space
            direction="vertical"
            size={2}
            css={css`
              text-align: center;
            `}
          >
            <Title
              css={css`
                color: rgb(74 88 96) !important;
                font-weight: 400 !important;
              `}
              level={2}
            >
              {headerText}
            </Title>
            <Text
              css={css`
                color: rgb(202 206 211) !important;
                font-size: 1.3rem;
                width: 50%;
             
     
              `}
              
            >
              {subHeaderText}
            </Text>
          </Space>

          {children}
        </Flex>
        <RenderIf condition={!isMobile}> {cardHeaderComponent} </RenderIf>
      </Flex>
    </Col>
  );
};
