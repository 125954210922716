import { Form } from "antd";
import { FormModal, PriortyStatusTag } from "components";
import { useAddPaymentsRequest } from "pages/Payments/api";
import {
  useAdvangePaymentModalState,
  useAdvangePaymentModalActions,
} from "pages/Payments/store/AdvancePaymentModal";
import { Priority } from "types";
import dayjs from "dayjs";

export const AdvangePaymentModal = () => {
  const [form] = Form.useForm();
  const { isOpen } = useAdvangePaymentModalState();
  const { close } = useAdvangePaymentModalActions();

  const { mutateAsync: addPaymentRequest } = useAddPaymentsRequest({
    refetchQueries: ["payment-requests"],
    successMessage: "Payment request has been sent successfully",
    errorMessage: "Something went wrong, please try again later",
  });

  const handleFormSubmit = async (values: any) => {
    addPaymentRequest(values);
  };

  const formFields: FormModal["formFields"] = [
    {
      label: "Amount",
      name: "amount",
      placeholder: "Enter amount",
      rules: [{ required: true, message: "Please enter amount" }],
      type: "number",
      prefix: "$",
    },
    {
      label: "Installments",
      name: "installments",
      placeholder: "Select installments",
      rules: [{ required: true, message: "Please enter installments" }],
      type: "select",
      options: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
      ],
    },

    {
      label: "Request Date",
      name: "request_date",
      placeholder: "Select request date",
      rules: [{ required: true, message: "Please select request date" }],
      type: "date",
    },
    {
      label: "Priority",
      name: "priority",
      placeholder: "Select priority",
      rules: [{ required: true, message: "Please select priority" }],
      type: "select",
      options: [
        { label: <PriortyStatusTag status={Priority.HIGH} />, value: "high" },
        {
          label: <PriortyStatusTag status={Priority.MEDIUM} />,
          value: "medium",
        },
        { label: <PriortyStatusTag status={Priority.LOW} />, value: "low" },
      ],
    },
    {
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rules: [{ required: true, message: "Please enter description" }],
      type: "textarea",
    },
  ];

  return (
    <FormModal
      open={isOpen}
      setOpen={close}
      title="Request advance payment"
      formFields={formFields}
      formInstance={form}
      onCancel={close}
      onOk={handleFormSubmit}
      initialValues={{
        request_date: dayjs().format("YYYY-MM-DD"),
        priority: Priority.LOW,
      }}
    />
  );
};
