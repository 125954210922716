import { css } from "@emotion/react";
import { Col } from "antd";
import { HrBuddyLogoLight } from "assets/logo";

export const TopSide = () => {
  return (
    <Col
      css={(theme: any) => css`
          width: 100%;
          background-color: ${theme.colorPrimary};
          padding: 10px 0;
          padding-bottom: 20px;
          * {
          margin: 0 auto;
          display: block;
        
        `}
      span={24}
    >
      <HrBuddyLogoLight height={80} width={200} />
    </Col>
  );
};
