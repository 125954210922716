import { NotificationProvider } from "contexts";
import EmotionProvider from "./EmotionProvider";
import AntProvider from "./AntProvider";
import ToasterProvider from "./ToasterProvider";
import RouteProvider from "./RouteProvider";
import ReactQueryProvider from "./ReactQueryProvider";
import LanguageProvider from "./LanguageProvider";
import AuthProvider from "./AuthProvider";
import AbilityProvider from "./AbilityProvider";
import { CommonProvider } from "./CommonProvider";

function AppProvider({ children }: any) {
  return (
    <CommonProvider>
      <AntProvider>
        <LanguageProvider>
          <ReactQueryProvider>
            <EmotionProvider>
              <NotificationProvider>
                <ToasterProvider />
                <RouteProvider>
                  <AuthProvider>
                    <AbilityProvider>{children} </AbilityProvider>
                  </AuthProvider>
                </RouteProvider>
              </NotificationProvider>
            </EmotionProvider>
          </ReactQueryProvider>
        </LanguageProvider>
      </AntProvider>
    </CommonProvider>
  );
}

export default AppProvider;
