import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

export const CompanyTab = () => {
  const { t } = useTranslation();
  const workerCount = [
    {
      value: "1-10",
      label: "1-10",
    },
    {
      value: "11-50",
      label: "11-50",
    },
    {
      value: "51-100",
      label: "51-100",
    },
    {
      value: "101-500",
      label: "101-500",
    },
    {
      value: "501-1000",
      label: "501-1000",
    },
    {
      value: "1000+",
      label: "1000+",
    },
  ];

  return (
    <>
      <Form.Item
        name="companyName"
        hasFeedback
        rules={[{ required: true, message: t("auth.companyNameReq") }]}
      >
        <Input placeholder={t("auth.companyName")} />
      </Form.Item>

      <Form.Item
        name="companyEmail"
        hasFeedback
        rules={[
          {
            required: true,
            message: t("auth.companyEmailReq"),
          },
        ]}
      >
        <Input placeholder={t("auth.companyEmail")} />
      </Form.Item>

      <Form.Item
        name="workerCount"
        hasFeedback
        rules={[{ required: true, message: t("auth.workerCountReq") }]}
      >
        <Select placeholder={t("auth.workerCount")} options={workerCount} />
      </Form.Item>
    </>
  );
};
