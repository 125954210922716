import { lazy } from "react";

export const Dashboard = lazy(() =>
  import("pages/Dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);

export const Settings = lazy(() =>
  import("pages/Settings").then((module) => ({
    default: module.Settings,
  }))
);

export const EmployeeRoutes = lazy(() =>
  import("pages/Employees").then((module) => ({
    default: module.EmployeeRoutes,
  }))
);

export const LeaveRequests = lazy(() =>
  import("pages/LeaveRequests").then((module) => ({
    default: module.LeaveRequestsRoutes,
  }))
);

export const Payments = lazy(() =>
  import("pages/Payments").then((module) => ({
    default: module.PaymentsRoutes,
  }))
);

export const NotFound = lazy(() =>
  import("pages/NotFound").then((module) => ({
    default: module.NotFound,
  }))
);

export const Unauthorized = lazy(() =>
  import("pages/Unauthorized").then((module) => ({
    default: module.Unauthorized,
  }))
);

export const Calendar = lazy(() =>
  import("pages/Calendar").then((module) => ({
    default: module.Calendar,
  }))
);
