import { Skeleton, Row, Col, Modal } from "antd";
import { PriortyStatusTag, PaymentStatusTag } from "components";
import { RenderIf, InfoRow } from "components/Common";
import { useGetPaymentRequest } from "pages/Payments/api";
import {
  usePaymentDetailsModalActions,
  usePaymentDetailsModalState,
} from "pages/Payments/store";

export const PaymentDetailsModal = () => {
  const { isOpen, id, onlyView } = usePaymentDetailsModalState();
  const { close } = usePaymentDetailsModalActions();

  const handleApprove = () => {
    console.log(id);
  };

  const { data: payment, isLoading } = useGetPaymentRequest(
    { id: id! },
    { enabled: isOpen }
  );

  return (
    <Modal
      open={isOpen}
      onCancel={close}
      title="Payment Detail"
      destroyOnClose
      width={600}
      okText="Approve"
      cancelText="Cancel"
      onOk={handleApprove}
      footer={onlyView ? null : undefined}
      
    >
      <RenderIf
        condition={!isLoading && !!payment}
        fallback={<Skeleton active paragraph={{ rows: 4 }} />}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={12}>
            <InfoRow
              text="Employee"
              content={
                payment?.employee?.name + " " + payment?.employee?.surname
              }
            />
          </Col>
          <Col span={12}>
            <InfoRow
              text="Priorty"
              content={<PriortyStatusTag status={payment?.priority} />}
            />
          </Col>
          <Col span={12}>
            <InfoRow text="Reason" content={payment?.paymentType?.name} />
          </Col>

          <Col span={12}>
            <InfoRow
              text="Status"
              content={<PaymentStatusTag status={payment?.status} />}
            />
          </Col>
          <Col span={24}>
            <InfoRow text="Description" content={payment?.description} />
          </Col>
        </Row>
      </RenderIf>
    </Modal>
  );
};
