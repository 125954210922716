import { Select } from "antd";
import { useState } from "react";
import { Option, SearchableSelectProps } from "./types";
import { findOption } from "./helpers/findOptions";
import { SearchableSelectCSS } from "./styles";
import NotFound from "./partials/NotFound";
import { useDebounce } from "hooks";

export const SearchableSelect = ({
  queryFn,
  optionFormat,
  placeholder,
  css,
  defaultOptions = [],
  onChange,
  value,
  params,
  ...props
}: SearchableSelectProps) => {
  const [options, setOptions] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { isLoading, data } = queryFn(
    {
      query: debouncedSearchQuery,
      ...params
    },
    {
      onSuccess: (data: any) => {
        const foundOption = findOption(defaultOptions, debouncedSearchQuery);
        const options =
          data.map(optionFormat) && foundOption.length > 0
            ? [...data.map(optionFormat), ...foundOption]
            : data.map(optionFormat);

        setOptions(options);
      },
      refetchOnMount: true,
      enabled: true,
    }
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };
  const handleSelect = (option: Option) => {
    onChange?.(option.value);
  };

  let preparedValue = value
    ? typeof value === "object"
      ? [value].map(optionFormat)
      : value
    : undefined;

  const handleClear = () => {
    onChange?.(null);
    preparedValue = undefined;
  };

  return (
    <Select
      options={options}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      value={preparedValue}
      css={(theme) =>
        SearchableSelectCSS({
          theme,
          custom: css,
          width: css?.width,
          height: css?.height,
        })
      }
      notFoundContent={<NotFound isLoading={isLoading} data={data} />}
      placeholder={placeholder}
      labelInValue
      showSearch
      allowClear
      filterOption={false}
      defaultValue={value}
      {...props}
    />
  );
};
