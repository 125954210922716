import { useMutation } from "@tanstack/react-query";
import { useNotification } from "contexts";
import { MutationConfig, queryClient } from "lib/react-query";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const useExtendedMutation = <
  MutationFnType extends (...args: any) => any
>(
  config?: MutationConfig<MutationFnType>
) => {
  const { api } = useNotification();

  const onSuccess = (data: any, variables: any, context: any) => {
    if (config?.refetchQueries?.length) {
      queryClient.refetchQueries(config.refetchQueries);
    }

    if (config?.successMessage) {
      delay(500).then(() => {
        api.success({
          message: config?.successMessage,
        });
      });
    }

    config?.onSuccess?.(data, variables, context);
  };

  const onError = (error: any, variables: any, context: any) => {
    if (config?.errorMessage) {
      const isString = typeof config?.errorMessage === "string";
      api.error({
        message: isString
          ? config?.errorMessage
          : error?.response?.data?.message ?? "Something went wrong",
      });
    }
    config?.onError?.(error, variables, context);
  };

  return useMutation({
    ...config,
    onSuccess,
    onError,
  });
};
