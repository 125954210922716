import { Drawer, Layout, theme } from "antd";
import React, { FC } from "react";
import { PageHeader } from "./Header/Header";
import { PageSidebar } from "./Sidebar/Sidebar";
import { css } from "@emotion/react";
import { RenderIf } from "components/Common";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Widget } from "components/Widget";
import { PaymentDetailsModal } from "pages/Payments";
import { LeaveDaysDetailsModal } from "pages/LeaveRequests/components/Modals";

const { Sider, Content } = Layout;

type MainLayoutProps = {
  children: React.ReactNode;
};

const Overlays = () => {
  return (
    <>
      <PaymentDetailsModal />
      <LeaveDaysDetailsModal />
    </>
  );
};

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const width = useWindowDimensions().width;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  const handleSidebarToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <Overlays />
      <Layout
        style={{
          height: "100vh",
          width: "100vw",
          background: colorBgLayout,
          overflow: "hidden",
        }}
      >
        <Widget />
        <Drawer
          open={openDrawer}
          onClose={handleSidebarToggle}
          placement="left"
          closable={false}
          width={"fit-content"}
          css={css`
            padding: 0;
            background: ${colorBgLayout};
            .ant-drawer-body {
              padding: 0;
            }
            .ant-drawer-content-wrapper {
              background: ${colorBgLayout};
            }
          `}
        >
          <PageSidebar handleSidebarToggle={handleSidebarToggle} />
        </Drawer>
        <RenderIf condition={width > 1400}>
          <PageSidebar />
        </RenderIf>

        <Layout
          style={{
            height: "100vh",
            background: colorBgLayout,
          }}
        >
          <PageHeader handleSidebarToggle={handleSidebarToggle} />
          <Content
            css={(theme: any) => css`
              min-height: 100vh;
              padding: 48px;
              width: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              background: ${theme.colorBgLayout};
              @media (max-width: ${theme.screenXL}px) {
                padding: 24px 24px;
              }
            `}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
