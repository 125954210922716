import { LeaveDayRequestPayload } from "./useGetLeaveDayRequest";
import { LeaveDayRequestsPayload } from "./useGetLeaveDayRequests";

export const leavedayRequestsKeys = {
  all: ["leavedayRequests"] as const,
  getLeaveDayRequest: (payload: LeaveDayRequestPayload) =>
    [...leavedayRequestsKeys.all, payload] as const,
  getLeaveDayRequests: (payload?: LeaveDayRequestsPayload) =>
    [...leavedayRequestsKeys.all, payload] as const,
};
