import { proxy, useSnapshot } from "valtio";

type PaymentDetailsModalState = {
  isOpen?: boolean;
  id: number | null;
  onlyView?: boolean;
};

const initialState = proxy<PaymentDetailsModalState>({
  isOpen: false,
  id: null,
  onlyView: false,
});

const actions = {
  open: () => {
    initialState.isOpen = true;
  },
  close: () => {
    initialState.id = null;
    initialState.onlyView = false;
    initialState.isOpen = false;
  },
  dispatch: (payload: PaymentDetailsModalState) => {
    Object.assign(initialState, payload);
    initialState.isOpen = true;
  },
};

export const usePaymentDetailsModalState = () => {
  return useSnapshot(initialState);
};

export const usePaymentDetailsModalActions = () => {
  return actions;
};
