import { defineAbility } from "@casl/ability";
import { Roles } from "types";

const generateEmployeePermissions = (can, cannot) => {
  cannot("show", "LeaveDaysCard");
  cannot('approve', 'LeaveDays');
  cannot('show', 'EmployeesPage');
};

const generateManagerPermissions = (can, cannot) => {
  can("show", "LeaveDaysCard");
  can('approve', 'LeaveDays');
  can('show', 'EmployeesPage');

};

export default function generatePermissions(role: Roles) {
  return defineAbility((can, cannot) => {
    role === "employee"
      ? generateEmployeePermissions(can, cannot)
      : generateManagerPermissions(can, cannot);
  });
}
