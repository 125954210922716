import i18n from "i18next";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .init({
    fallbackLng: "en",
    supportedLngs: ["en", "tr"],
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

export default i18n;
