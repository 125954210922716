import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu as AntMenu } from "antd";
import { adminMenuItems, employeeMenuItems, managerMenuItems } from "./helpers";
import { AuthUser } from "pages/Auth";
import { useCommonContext } from "providers/CommonProvider";

type MenuProps = {
  user?: AuthUser;
  handleSidebarToggle?: () => void;
};

const items: Record<string, any> = {
  admin: adminMenuItems,
  employee: employeeMenuItems,
  manager: managerMenuItems,
};

const Menu = ({ user, handleSidebarToggle }: MenuProps) => {
  const [selectedKey, setSelectedKey] = useState("");
  const {isDarkMode} = useCommonContext();
  const navigate = useNavigate();
  const menuItems = [...(items[user?.role ?? ""] ?? [])];

  const handleSelect = (info: any) => {
    navigate(info.item.props.path);
    handleSidebarToggle && handleSidebarToggle();
  };

  useEffect(() => {
    const path = window.location.pathname;
    const item = menuItems.find((item) => item.path === path);
    if (item) {
      setSelectedKey(item.key);
    }
  }, [menuItems]);
  return (
    <AntMenu
      defaultSelectedKeys={["dashboard"]}
      selectedKeys={[selectedKey]}
      items={menuItems}
      onSelect={handleSelect}
      css={(theme: any) => css`
      background: ${isDarkMode ? "#2d2d2d" : theme.colorWhite};
      margin-top: 20px;
        border: none !important;
        .ant-menu-item {
          display: flex !important;
        }
      `}
    />
  );
};

export default Menu;
