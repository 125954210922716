import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import storage from "utils/storage";
import { NotFound, Unauthorized } from "./components";

export const AppRoutes = () => {
  const token = storage.getToken();
  const commonRoutes = [
    {
      path: "*",
      element: <NotFound />,
      id: "404",
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
      id: "403",
    },
  ];
  const routes = token
    ? [...protectedRoutes, ...commonRoutes]
    : [...publicRoutes];
  const element = useRoutes([...routes]);
  return <>{element}</>;
};
