import { proxy, useSnapshot } from "valtio";

type AdvangePaymentModalState = {
  isOpen: boolean;
};

const initialState = proxy<AdvangePaymentModalState>({
  isOpen: false,
});

const actions = {
  open: () => {
    initialState.isOpen = true;
  },
  close: () => {
    initialState.isOpen = false;
  },
  dispatch: () => {
    initialState.isOpen = true;
  },
};

export const useAdvangePaymentModalState = () => {
  return useSnapshot(initialState);
};

export const useAdvangePaymentModalActions = () => {
  return actions;
};
