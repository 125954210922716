import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

export const PersonalTab = () => {
  const { t } = useTranslation();
  const mockCities = [
    {
      value: "istanbul",
      label: "İstanbul",
    },
    {
      value: "ankara",
      label: "Ankara",
    },
    {
      value: "izmir",
      label: "İzmir",
    },
  ];

  return (
    <>
      <Form.Item
        hasFeedback
        name="name"
        rules={[{ required: true, message: t("auth.nameReq") }]}
      >
        <Input placeholder={t("auth.name")} />
      </Form.Item>

      <Form.Item
        hasFeedback
        name="surname"
        rules={[{ required: true, message: t("auth.surnameReq") }]}
      >
        <Input placeholder={t("auth.surname")} />
      </Form.Item>

      <Form.Item
        hasFeedback
        name="city"
        rules={[{ required: true, message: t("auth.cityReq") }]}
      >
        <Select placeholder={t("auth.selectCity")} options={mockCities} />
      </Form.Item>
    </>
  );
};
