import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

export const ContactTab = () => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        hasFeedback
        name="email"
        rules={[
          {
            required: true,
            message: t("auth.emailRequired"),
          },
          {
            type: "email",
            message: t("auth.validEmailReq"),
          },
        ]}
      >
        <Input type="email" placeholder={t("auth.emailPlchldr")} />
      </Form.Item>

      <Form.Item
        hasFeedback
        name="phone"
        rules={[
          {
            required: true,
            message: t("auth.phoneRequired"),
          },
          {
            min: 10,
            message: t("auth.validPhoneReq"),
          },
        ]}
      >
        <Input type="tel" placeholder={t("auth.phonePlchldr")} />
      </Form.Item>
    </>
  );
};
