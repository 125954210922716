export type RenderIfProps = {
  condition: boolean
  children: React.ReactNode
  fallback?: React.ReactNode | JSX.Element | Element
}

export const RenderIf = ({ condition, children, fallback }: RenderIfProps) => {
  if (condition) {
    return <>{children}</>
  } else {
    if (fallback) {
      return <>{fallback}</>
    } else {
      return <></>
    }
  }
}
