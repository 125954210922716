import { AxiosError } from "axios";
import {
  QueryClient,
  UseQueryOptions,
  UseMutationOptions,
  DefaultOptions,
  QueryKey,
  QueryFunctionContext,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

type CustomMutationParameters = {
  refetchQueries?: QueryKey | ((data: any) => readonly QueryKey[])[]
  responseTransformer?: (data: any) => any;
  requestTransformer?: (data: any) => any;
  successMessage?: string;
  errorMessage?: string | boolean;
};
export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

export type InfiniteQueryConfig<QueryFnType extends (...args: any) => any> =
  Omit<
    UseInfiniteQueryOptions<ExtractFnReturnType<QueryFnType>>,
    | "querKey"
    | "queryFn"
    | "refetchOnWindowFocus"
    | "refetchOnReconnect"
    | "refetchOnMount"
    | "useErrorBoundary"
    | "refetchInterval"
  >;

export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
  > &
    CustomMutationParameters;

export type QueryContext<
  keys extends { [K: string]: QueryKey | ((...params: any[]) => QueryKey) },
  mode extends keyof keys
> = keys[mode] extends (...params: any[]) => QueryKey
  ? QueryFunctionContext<ReturnType<keys[mode]>>
  : keys[mode] extends QueryKey
  ? QueryFunctionContext<keys[mode]>
  : never;
