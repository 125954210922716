import { Avatar, Dropdown, Menu, Space } from "antd";
import { useLogout } from "lib/auth";
import { userDropdown } from "./styles";
import { DisconnectOutlined, UserOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

type UserDropdownProps = {
  user: any;
};
export const UserDropdown = ({ user }: UserDropdownProps) => {
  const { mutate } = useLogout();
  const navigate = useNavigate();
  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

  const getRandomColor = () => {
    return ColorList[Math.floor(Math.random() * ColorList.length)];
  };

  const handleLogout = () => mutate({});

  const goToProfile = () => {
    navigate("/employees/" + user.id);
  };

  const menu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} onClick={goToProfile}>
        Profile
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item icon={<DisconnectOutlined />} onClick={handleLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Space css={userDropdown}>
        <Avatar
          src={user?.image}
          size={24}
          style={{
            backgroundColor: getRandomColor(),
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: "12px",
            margin: 0,
          }}
          css={css`
            .ant-avatar-string {
              line-height: 22px !important;
            }
          `}
        >
          <span
            css={css`
              text-transform: uppercase;
            `}
          >
            {user?.name[0]}
          </span>
        </Avatar>
      </Space>
    </Dropdown>
  );
};
