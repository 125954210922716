import { proxy, useSnapshot } from "valtio";

type AddHolidayDrawerState = {
  isOpen: boolean;
};

const initialState = proxy<AddHolidayDrawerState>({
  isOpen: false,
});

const actions = {
  open: () => {
    initialState.isOpen = true;
  },
  close: () => {
    initialState.isOpen = false;
  },
  dispatch: () => {
    initialState.isOpen = true;
  },
};

export const AddHolidayDrawerState = () => {
  return useSnapshot(initialState);
};

export const useAddHolidayDrawerActions = () => {
  return actions;
};
