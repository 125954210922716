import AppProvider from "providers/app";
import { AppRoutes } from "routes";
import "index.css";
import { notification } from "antd";

function App() {
  const [api, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </>
  );
}

export default App;
