import { Badge, Popover, Space, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import { BellOutlined, MenuOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { useState } from "react";
import { Logo } from "assets/Icons";

export const NotificationDropdown = () => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <Popover
      content={<>d</>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Badge
        css={css`
          :where(
              .css-dev-only-do-not-override-125ht9m
            ).ant-badge.ant-badge-status {
            line-height: initial;
          }
        `}
        dot
        color={colorPrimary}
      >
        <BellOutlined style={{ fontSize: "1.2rem" }} />
      </Badge>
    </Popover>
  );
};

type PageHeaderProps = {
  handleSidebarToggle: () => void;
};

export const PageHeader = ({ handleSidebarToggle }: PageHeaderProps) => {
  return (
    <Header
      css={(theme: any) => css`
        display: none;
        @media (max-width: ${theme.screenXL}px) {
          display: block;
        }
      `}
    >
      <Space
        size={24}
        css={css`
          text-align: center;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 1rem;

          .ant-space-item {
            display: flex;
            align-items: center;
          }
        `}
      >
        <MenuOutlined
          onClick={handleSidebarToggle}
          style={{ fontSize: 28, cursor: "pointer" }}
        />
        <Logo
          css={css`
            width: 150px;
            margin-top: 0.5rem;
          `}
        />
      </Space>
    </Header>
  );
};
