import forgetPasswordImage from "assets/auth/forget-password.png";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { SendEmailVerificationPayload } from "../api/useSendEmailVerification";
import { useSendForgetPasswordEmail } from "../api/useSendForgetPasswordEmail";
import { AuthLayout } from "../components/Layout";
import { Button, Form, Input, Typography } from "antd";
import { css } from "@emotion/react";

const { Text } = Typography;
export const ResetPassword = () => {
  const { mutateAsync, isLoading } = useSendForgetPasswordEmail();
  const navigation = useNavigate();

  const submitHandler = async (values: SendEmailVerificationPayload) => {
    mutateAsync(values)
      .then((res) => {
        if (res) {
          toast.success("Password reset link sent successfully");
          navigation("/auth/login");
        }
      })
      .catch((err) => {
        toast.error(
          err.data?.message ?? "Something went wrong, please try again"
        );
      });
  };

  return (
    <AuthLayout
      image={forgetPasswordImage}
      headerText="Reset Password"
      subHeaderText="Fill the form below to reset your password"
    >
      <Form
        name="login"
        initialValues={{ email: "" }}
        onFinish={submitHandler}
        css={css`
          padding: 1rem 0;
        `}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email address!" },
            {
              type: "email",
              message: "Please enter a validate email!",
            },
          ]}
        >
          <Input
            style={{ padding: ".7rem" }}
            placeholder="Enter your email address"
          />
        </Form.Item>

        <Form.Item
          css={css`
            text-align: center;
          `}
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              css={css`
                width: 100%;
                height: 2.4rem;
                text-align: center;
                font-size: 1.2rem;
                border-radius: 1rem;
              `}
            >
              Send Reset Link
            </Button>
            <Text
              css={css`
                width: 100%;
                margin-top: 1rem;
                display: inline-block;
                font-size: 1rem;
              `}
            >
              Don't have an account?{" "}
              <Link to="/auth/register">
                <Text
                  css={(theme: any) => css`
                    font-weight: 600;
                    color: ${theme.colorPrimary};
                  `}
                >
                  Register
                </Text>
              </Link>
            </Text>
          </Form.Item>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
