import {
  HomeOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  CreditCardOutlined, CalendarOutlined, SettingOutlined
} from "@ant-design/icons";

export const adminMenuItems = [
  {
    icon: <HomeOutlined />,
    label: "Dashboard",
    key: "dashboard",
    path: "/",
  },
  {
    icon: <TeamOutlined />,
    label: "Employees",
    key: "employees",
    path: "/employees",
  },
  {
    icon: <ClockCircleOutlined />,
    label: "Leave Requests",
    key: "leave-requests",
    path: "/leave-requests",
  },
  {
    icon: <CreditCardOutlined />,
    label: "Payments",
    key: "payments",
    path: "/payments",
  },

  {
    icon: <CalendarOutlined />,
    label: "Calendar",
    key: "calendar",
    path: "/calendar",
  },

  {
    icon: <SettingOutlined />,
    label: "Settings",
    key: "settings",
    path: "/settings",
  },
];
