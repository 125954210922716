import { configureAuth } from "react-query-auth";

import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
  AuthUser,
} from "pages/Auth";
import storage from "utils/storage";
import { QueryFunctionContext, QueryKey } from "@tanstack/query-core";

async function handleUserResponse(data: any) {
  const { access_token, result } = data;
  storage.setToken(access_token);
  return result;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response.data);
  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

async function userFn(context: QueryFunctionContext<QueryKey, any>) {
  const data = await getUser();
  return data;
}

export const { useLogin, useLogout, useRegister, AuthLoader, useUser,  } =
  configureAuth<
    AuthUser,
    undefined,
    LoginCredentialsDTO,
    RegisterCredentialsDTO
  >({
    loginFn: loginFn,
    userFn: userFn,
    registerFn: registerFn,
    logoutFn: logoutFn,
    userKey: ["user"]
  });
