import { css } from "@emotion/react";
import { Tag } from "antd";
import { EmployeeLeaveDaysStatus, Indexable } from "types";

type LeaveDayStatusTagProps = {
  status?: EmployeeLeaveDaysStatus;
  text?: string;
};

export const LeaveDayStatusTag = ({ status }: LeaveDayStatusTagProps) => {
  const colors: Indexable<object> = {
    pending: "orange",
    approved: "green",
    rejected: "red",
  };

  return (
    <Tag
      css={css`
        text-transform: capitalize;
      `}
      color={colors[status!]}
    >
      {status}
    </Tag>
  );
};
