import { useLogin } from "lib/auth";
import { Link, useNavigate } from "react-router-dom";
import { AuthLayout } from "../components/Layout";
import { LoginCredentialsDTO } from "../types";
import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Tooltip,
  Typography,
  theme,
} from "antd";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNotification } from "contexts";
import { useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { RenderIf } from "components/Common";
import { useSendEmailVerification } from "../api/useSendEmailVerification";

const { Text } = Typography;

export const Login = () => {
  const { mutateAsync, isLoading } = useLogin();
  const [form] = Form.useForm();
  const { api } = useNotification();
  const navigation = useNavigate();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const { mutateAsync: sendEmailVerification, isLoading: isEmailSending } =
    useSendEmailVerification({
      successMessage: "Email sent successfully",
    });
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | undefined>();
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { t } = useTranslation();
  const submitHandler = async (values: LoginCredentialsDTO) => {
    mutateAsync(values)
      .then((res) => {
        if (res) {
          api.success({
            message: t("auth.loginSuccess"),
          });
          navigation("/");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.emailStatus === false) {
          setIsEmailVerified(false);
          api.error({
            message: "E-Posta adresinizi Approveyın",
          });
          return;
        }
        api.error({
          message: err.response?.data?.message ?? "Giriş Başarısız",
        });
      });
  };

  const handleSendEmailVerification = () => {
    form.validateFields(["email"]).then((values) => {
      sendEmailVerification({
        email: values.email,
      }).then(() => {
        setIsEmailVerified(true);
      });
    });
  };

  return (
    <AuthLayout
      headerText={t("auth.login")}
      subHeaderText={
        <>
          {t("auth.loginPage.firstText")} <br />
          {t("auth.loginPage.secondText")}
        </>
      }
    >
      <Form
        name="login"
        form={form}
        initialValues={{ email: "", password: "", remember: false }}
        onFinish={submitHandler}
        css={(theme: any) => css`
          padding: 1rem 0;
          flex: 1;
          min-width: 400px;

          @media (max-width: ${theme.screenXL}px) {
            min-width: auto;
          }
        `}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t("auth.emailRequired") },
            {
              type: "email",
              message: t("auth.validEmailReq"),
            },
          ]}
        >
          <Input
            prefix={
              <RenderIf condition={isEmailVerified === false}>
                <Tooltip
                  title={"Send verification email"}
                  placement="topLeft"
                  defaultOpen={isEmailVerified === false}
                >
                  <Button
                    type="link"
                    onClick={handleSendEmailVerification}
                    size="small"
                    loading={isEmailSending}
                    icon={<SendOutlined />}
                  ></Button>
                </Tooltip>
              </RenderIf>
            }
            placeholder={t("auth.emailPlchldr")}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: t("auth.passwordRequired") }]}
        >
          <Input.Password placeholder={t("auth.passwordPlchldr")} />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Flex justify="space-between" style={{ width: "100%" }}>
            <Form.Item noStyle>
              <Checkbox>{t("auth.rememberMe")}</Checkbox>
            </Form.Item>

            <Button
              type="link"
              size="small"
              onClick={() => navigation("/auth/reset-password")}
            >
              {t("auth.loginPage.forgotPassword")}
            </Button>
          </Flex>
        </Form.Item>

        <Form.Item
          css={css`
            text-align: center;
          `}
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              css={css`
                width: 100%;
                height: 2.4rem;
                text-align: center;
                font-size: 1.2rem;
                border-radius: 1rem;
              `}
            >
              {t("auth.login")}
            </Button>
            <Text
              css={css`
                width: 100%;
                margin-top: 1rem;
                display: inline-block;
                font-size: 1rem;
              `}
            >
              {t("auth.dontHaveAccount")}{" "}
              <Link to="/auth/register">
                <Text
                  css={css`
                    font-weight: 600;
                    color: ${colorPrimary};
                  `}
                >
                  {t("auth.register")}
                </Text>
              </Link>
            </Text>
          </Form.Item>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
