import { css } from "@emotion/react";
import { Row } from "antd";
import { RenderIf } from "components/Common";
import { AuthLayoutProps } from "./types";
import { LeftSide, TopSide, RightSide } from "./partials";
import useWindowDimensions from "hooks/useWindowDimensions";

export const AuthLayout = ({
  children,
  image,
  headerText,
  subHeaderText,
  cardHeaderComponent,
  headerClassName,
}: AuthLayoutProps) => {
  const width = useWindowDimensions().width;
  const isMobile = width < 1200;

  const layoutCSS = (theme: any) => css`
    height: 100%;
    position: relative;
    width: 100%;
    background-color: ${isMobile ? "white" : theme.colorBgLayout};
  `;

  return (
    <Row
      css={layoutCSS}
      align={isMobile ? "top" : "middle"}
      justify={isMobile ? "start" : "space-between"}
    >
      <RenderIf condition={isMobile} fallback={<LeftSide />}>
        <span
          css={css`
            position: absolute;
            height: 10px;
            width: 100%;
            background-color: white;
            width: 100%;
            z-index: 1;
            padding: 10px 0;
            top: 6rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
          `}
        ></span>
        <TopSide />
      </RenderIf>

      <RightSide
        headerText={headerText}
        subHeaderText={subHeaderText}
        cardHeaderComponent={cardHeaderComponent}
        headerClassName={headerClassName}
      >
        {children}
      </RightSide>
    </Row>
  );
};
