/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'

import { API_URL } from '../config'
import QueryString from 'qs'
import storage from 'utils/storage'

export const axiosMain = Axios.create({
  baseURL: `${API_URL}/api`
})

function authRequestInterceptor(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const token = storage.getToken()
  if (token) {
    config.headers!.authorization = `${token}`
  }
  config.headers!.Accept = 'application/json'
  return config
}

axiosMain.interceptors.request.use(authRequestInterceptor)
